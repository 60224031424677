import axios from "axios";
import { EMI_BOOK } from "./constants";
import { getHeaders } from "./head";

export function customerRegistrations(payload) {
  return axios.get(`${EMI_BOOK}/v3/mdm/customer-registrations`, {
    params: {
      page: payload.page,
      imei1: payload.imei1,
      imei2: payload.imei2,
      mobile: payload.mobile,
      size: payload.size,
    },
    headers: getHeaders(true),
  });
}
export const adminActions = (id, action) => {
  return axios.post(`${EMI_BOOK}/v3/mdm/admin/action/${id}`, null, {
    params: { action },
    headers: getHeaders(true),
  });
};
export const syncData = (id) => {
  return axios.post(`${EMI_BOOK}/v1/mdm/sync-data/${id}`, null, {
    headers: getHeaders(true),
  });
};

export const updateEMIDetails = (id, payload) => {
  return axios.put(`${EMI_BOOK}/v1/mdm/emi-details/${id}`, payload, {
    headers: getHeaders(true),
  });
};

export const updateEMIStatus = (id, emiId, status) => {
  return axios.put(`${EMI_BOOK}/v1/mdm/emi-status/${id}/${emiId}`, null, {
    params: { status },
    headers: getHeaders(true),
  });
};

export const updateKYCDetails = (id, payload) => {
  return axios.put(`${EMI_BOOK}/v1/mdm/kyc-details/${id}`, payload, {
    headers: getHeaders(true),
  });
};

export const getDevices = (enterprise, pageToken) => {
  return axios.get(`${EMI_BOOK}/v2/mdm/devices`, {
    headers: getHeaders(true),
    params: { enterprise, pageToken },
  });
};

export function getEnterprisesForDevices() {
  return axios.get(`https://mdm.schemesbook.com/mdm-generic/enterprises`, {
    headers: {
      Authorization:
        "Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiI5NTg0OTQ1NTU4IiwiaXNzIjoiT0sgRU1JIiwiZXhwIjoxNzMwMDAxNDA1LCJ1c2VySWQiOiI2NzA5MTU1NzZlZDRlNjgyOWVmNGJlNGIiLCJpYXQiOjE3MjkxMzc0MDV9.goWjJa2vmKXfUhqqFXRVu6jgwlSMACH6mKiXi8RUQsF-kU-xCd_CoDHpKR6XQ4r6QwLHi3BiRWTZ9M-sTYaAsw",
    },
  });
}

export function checkEnterprisesAndUpdateCount() {
  return axios.get(
    `https://mdm.schemesbook.com/mdm-generic/enterprises/check`,
    {
      headers: {
        Authorization:
          "Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiI5NTg0OTQ1NTU4IiwiaXNzIjoiT0sgRU1JIiwiZXhwIjoxNzMwMDAxNDA1LCJ1c2VySWQiOiI2NzA5MTU1NzZlZDRlNjgyOWVmNGJlNGIiLCJpYXQiOjE3MjkxMzc0MDV9.goWjJa2vmKXfUhqqFXRVu6jgwlSMACH6mKiXi8RUQsF-kU-xCd_CoDHpKR6XQ4r6QwLHi3BiRWTZ9M-sTYaAsw",
      },
    }
  );
}

export const findIMEI = (enterprise, imei) => {
  return axios.get(
    `https://mdm.schemesbook.com/mdm-generic/${enterprise}/devices/imei/${imei}`,
    {
      headers: {
        Authorization:
          "Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiI5NTg0OTQ1NTU4IiwiaXNzIjoiT0sgRU1JIiwiZXhwIjoxNzMwMDAxNDA1LCJ1c2VySWQiOiI2NzA5MTU1NzZlZDRlNjgyOWVmNGJlNGIiLCJpYXQiOjE3MjkxMzc0MDV9.goWjJa2vmKXfUhqqFXRVu6jgwlSMACH6mKiXi8RUQsF-kU-xCd_CoDHpKR6XQ4r6QwLHi3BiRWTZ9M-sTYaAsw",
      },
    }
  );
};

export const deleteDevice = ({ enterprise, deviceId }) => {
  console.log(enterprise, deviceId);
  return axios.delete(
    `https://mdm.schemesbook.com/mdm-generic/enterprises/${enterprise}/device/${deviceId}`,
    {
      headers: {
        Authorization:
          "Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiI5NTg0OTQ1NTU4IiwiaXNzIjoiT0sgRU1JIiwiZXhwIjoxNzMwMDAxNDA1LCJ1c2VySWQiOiI2NzA5MTU1NzZlZDRlNjgyOWVmNGJlNGIiLCJpYXQiOjE3MjkxMzc0MDV9.goWjJa2vmKXfUhqqFXRVu6jgwlSMACH6mKiXi8RUQsF-kU-xCd_CoDHpKR6XQ4r6QwLHi3BiRWTZ9M-sTYaAsw",
      },
    }
  );
};

export const updatePolicy = ({ enterprise, deviceId, policy }) => {
  console.log(enterprise, deviceId);
  return axios.put(
    `https://mdm.schemesbook.com/mdm-generic/enterprises/${enterprise}/device/${deviceId}/policy/${policy}`,
    {},
    {
      headers: {
        Authorization:
          "Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiI5NTg0OTQ1NTU4IiwiaXNzIjoiT0sgRU1JIiwiZXhwIjoxNzMwMDAxNDA1LCJ1c2VySWQiOiI2NzA5MTU1NzZlZDRlNjgyOWVmNGJlNGIiLCJpYXQiOjE3MjkxMzc0MDV9.goWjJa2vmKXfUhqqFXRVu6jgwlSMACH6mKiXi8RUQsF-kU-xCd_CoDHpKR6XQ4r6QwLHi3BiRWTZ9M-sTYaAsw",
      },
    }
  );
};

export const updateCurrentEnterprise = ({ enterprise }) => {
  return axios.put(
    `https://mdm.schemesbook.com/mdm-generic/enterprises/${enterprise}/current`,
    {},
    {
      headers: {
        Authorization:
          "Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiI5NTg0OTQ1NTU4IiwiaXNzIjoiT0sgRU1JIiwiZXhwIjoxNzMwMDAxNDA1LCJ1c2VySWQiOiI2NzA5MTU1NzZlZDRlNjgyOWVmNGJlNGIiLCJpYXQiOjE3MjkxMzc0MDV9.goWjJa2vmKXfUhqqFXRVu6jgwlSMACH6mKiXi8RUQsF-kU-xCd_CoDHpKR6XQ4r6QwLHi3BiRWTZ9M-sTYaAsw",
      },
    }
  );
};
