import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/styles/tailwind.css";
import "assets/styles/index.css";
// layouts
import Admin from "../src/layouts/Admin.js";
import Login from "views/auth/Login.js";
import Users from "views/admin/Users.js";
import { ToastContainer } from "react-toastify";
import Keys from "./views/admin/Keys.js";
import AllOrders from "views/admin/AllOrders.js";
import Promotions from "views/admin/Promotions.js";
import AddPromotions from "views/admin/AddPromotions.js";
import CreateOrders from "views/admin/CreateOrders.js";
import CustomerReg from "views/admin/CustomerReg.js";
import CreateNotifications from "views/admin/CreateNotifications.js";
import Devices from "views/admin/Devices.js";
import Enterprises from "views/admin/Enterprises.js";
import { Provider } from "react-redux";
import store from "./redux/store.js";
import Issues from "views/admin/Issues.js";
import TOTP from "views/admin/Totp.js";
import GoogleTOTP from "views/admin/GoogleTOTP.js";

// views without layouts
const theme = createTheme({
  typography: {
    fontFamily: ["Montserrat"].join(","),
  },
});
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <ToastContainer />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="admin" element={<Admin />}>
            <Route path="Users" element={<Users />} />
            <Route path="Keys" element={<Keys />} />
            <Route path="Orders" element={<AllOrders />} />
            <Route path="Issue" element={<Issues />} />
            <Route path="CreateOrders" element={<CreateOrders />} />
            <Route path="Promotions" element={<Promotions />} />
            <Route path="AddPromotions" element={<AddPromotions />} />
            <Route path="CustomerRegistration" element={<CustomerReg />} />
            <Route
              path="CreateNotifications"
              element={<CreateNotifications />}
            />
            <Route path="Totp" element={<TOTP />} />
            <Route path="GoogleTOTP" element={<GoogleTOTP />} />
            <Route path="Devices" element={<Devices />} />
            <Route path="Enterprises" element={<Enterprises />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  </Provider>
);
