import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import TableHeader from "components/Table/TableHeader";
import TableElement from "components/Table/TableElement";
import { customerRegistrations } from "apis/customerRegistration";
import Pagination from "@mui/material/Pagination";
import MatAutoComplete from "components/MatAutoComplete/MatAutoComplete";
import Stack from "@mui/material/Stack";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { formatDate } from "utils";
import Grid from "@mui/material/Unstable_Grid2";
import { useDispatch, useSelector } from "react-redux";
import { setLoadingPanel } from "../../redux/features/loading";
import CircularIndeterminateModal from "../../utils/Loading";
import { adminActions } from "apis/customerRegistration";

export default function CustomerReg() {
  const color = "light";
  const isMobile = useMediaQuery("(max-width:600px)");
  const [reg, setReg] = useState([]);
  const isLoading = useSelector((state) => state.loadingReducer.isLoading);
  const dispatch = useDispatch();
  const [totalPages, setTotalPages] = useState(1);
  const [payload, setPayload] = useState({
    page: 0,
    mobile: "",
    imei1: "",
    imei2: "",
    size: 25,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [isSearching, setIsSearching] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const getRegistrations = useCallback(
    async (filters) => {
      if (isInitialLoad || isSearching) {
        dispatch(setLoadingPanel(true));
      }
      try {
        const { data } = await customerRegistrations(filters);
        setReg(data.data);
        setTotalPages(data.pages);
        if (isInitialLoad) {
          setIsInitialLoad(false);
        }
        if (isSearching) {
          setIsSearching(false);
        }
        dispatch(setLoadingPanel(false));
      } catch (err) {
        toast(`${err.response?.status} ${err?.response?.data?.message}`, {
          type: "error",
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
        });
      } finally {
        if (isInitialLoad) {
          setIsInitialLoad(false);
        }
        if (isSearching) {
          setIsSearching(false);
        }
        dispatch(setLoadingPanel(false));
      }
    },
    [dispatch, isInitialLoad, isSearching]
  );

  const isValidLength = (value, length) =>
    value === null || value === "" || value.length === length;

  const handleAdminActions = useCallback(
    async (id, action) => {
      try {
        dispatch(setLoadingPanel(true));
        const { data } = await adminActions(id, action);
        toast(`${data?.message}`, {
          type: "success",
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
        });
        dispatch(setLoadingPanel(false));
      } catch (err) {
        toast(`${err.response?.status} ${err?.response?.data?.message}`, {
          type: "error",
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
        });
      } finally {
        if (isInitialLoad) {
          setIsInitialLoad(false);
        }
        if (isSearching) {
          setIsSearching(false);
        }
        dispatch(setLoadingPanel(false));
      }
    },
    [dispatch, isInitialLoad, isSearching]
  );

  useEffect(() => {
    const fetchData = async () => {
      const isMobileValid = isValidLength(payload.mobile, 10);
      const isImei1Valid = isValidLength(payload.imei1, 15);
      const isImei2Valid = isValidLength(payload.imei2, 15);

      if (isMobileValid && isImei1Valid && isImei2Valid) {
        const obj = {
          ...payload,
          mobile: payload.mobile.length === 10 ? payload.mobile : null,
          imei1: payload.imei1.length === 15 ? payload.imei1 : null,
          imei2: payload.imei2.length === 15 ? payload.imei2 : null,
        };
        await getRegistrations(obj);
      }
    };
    fetchData();
  }, [getRegistrations, payload]);

  const handleEmiDetailsClick = (customer) => {
    setSelectedCustomer(customer);
    setIsModalOpen(true);
  };

  const handlePageChange = (event, value) => {
    setPayload((prev) => ({
      ...prev,
      page: value - 1, // Pagination component is 1-based, API is 0-based
    }));
  };

  return (
    <>
      <CircularIndeterminateModal
        open={isLoading && (isInitialLoad || isSearching)}
      />{" "}
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }>
        <div className="block w-full overflow-x-auto p-8">
          <form>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <FormControl sx={{ width: "100%" }} variant="outlined">
                  <InputLabel
                    htmlFor="outlined-adornment-password"
                    sx={{
                      fontSize: {
                        xs: "12px",
                        sm: "13x",
                        md: "13px",
                        lg: "16px",
                      },
                    }}>
                    Search by Mobile Number
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-weight"
                    label={
                      <span className="text-xs sm:text-sm md:text-base lg:text-lg font-medium">
                        Search by Mobile Number
                      </span>
                    }
                    InputProps={{
                      sx: {
                        fontSize: {
                          xs: "12px",
                          sm: "14px",
                          md: "16px",
                          lg: "16px",
                        },
                      },
                    }}
                    inputProps={{
                      maxLength: 10,
                    }}
                    aria-describedby="outlined-weight-helper-text"
                    onChange={(e) => {
                      if (e.target.value.length === 10) {
                        setIsSearching(true);
                      }
                      setPayload({
                        ...payload,
                        mobile: e.target.value,
                      });
                    }}
                    value={"" + payload.mobile}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl sx={{ width: "100%" }} variant="outlined">
                  <InputLabel
                    htmlFor="outlined-adornment-password"
                    sx={{
                      fontSize: {
                        xs: "12px",
                        sm: "13x",
                        md: "13px",
                        lg: "16px",
                      },
                    }}>
                    Search by IMEI 1
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-weight"
                    label={
                      <span className="text-xs sm:text-sm md:text-base lg:text-lg font-medium">
                        Search by Mobile Number
                      </span>
                    }
                    InputProps={{
                      sx: {
                        fontSize: {
                          xs: "12px",
                          sm: "14px",
                          md: "16px",
                          lg: "16px",
                        },
                      },
                    }}
                    inputProps={{
                      maxLength: 15,
                    }}
                    aria-describedby="outlined-weight-helper-text"
                    onChange={(e) => {
                      if (e.target.value.length === 15) {
                        setIsSearching(true);
                      }
                      setPayload({
                        ...payload,
                        imei1: e.target.value,
                      });
                    }}
                    value={"" + payload.imei1}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl sx={{ width: "100%" }} variant="outlined">
                  <InputLabel
                    sx={{
                      fontSize: {
                        xs: "12px",
                        sm: "13x",
                        md: "13px",
                        lg: "16px",
                      },
                    }}
                    htmlFor="outlined-adornment-password">
                    Search by IMEI 2
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-weight"
                    label={
                      <span className="text-xs sm:text-sm md:text-base lg:text-lg font-medium">
                        Search by Mobile Number
                      </span>
                    }
                    inputProps={{
                      maxLength: 15,
                    }}
                    InputProps={{
                      sx: {
                        fontSize: {
                          xs: "12px",
                          sm: "14px",
                          md: "16px",
                          lg: "16px",
                        },
                      },
                    }}
                    aria-describedby="outlined-weight-helper-text"
                    onChange={(e) => {
                      if (e.target.value.length === 15) {
                        setIsSearching(true);
                      }
                      setPayload({
                        ...payload,
                        imei2: e.target.value,
                      });
                    }}
                    value={"" + payload.imei2}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </form>
        </div>
        <div className="flex justify-center my-4">
          <Stack spacing={2}>
            <Pagination
              count={totalPages}
              page={payload.page + 1}
              color="primary"
              onChange={handlePageChange}
              siblingCount={0}
              boundaryCount={isMobile ? 1 : 2}
            />
          </Stack>
        </div>{" "}
        <div className="block w-full overflow-x-auto lg:p-8 py-3 px-5">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-100">
              <tr className="divide-x divide-gray-200 ">
                <TableHeader heading="S no." />
                <TableHeader heading="Name" />
                <TableHeader heading="Status" />
                <TableHeader heading="Version" />
                <TableHeader heading="Locked" />
                <TableHeader heading="Mobile" />
                <TableHeader heading="IMEIS" />
                <TableHeader heading="Retailer" />
                <TableHeader heading="Created On" />
                <TableHeader heading="" />
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {reg.map((item, i) => {
                return (
                  <tr
                    key={i}
                    className="divide-x divide-gray-200 hover:bg-slate-100">
                    <TableElement value={i + 1} />

                    <TableElement
                      value={
                        <span className="font-semibold">
                          {item?.customerDetails?.name}
                        </span>
                      }
                      type="element"
                    />
                    <TableElement value={item?.deviceData?.status} />
                    <TableElement value={item?.version} />
                    <TableElement
                      value={
                        item?.deviceData?.locked ? (
                          <p className="text-red-500">YES</p>
                        ) : (
                          <p className="text-green-500">NO</p>
                        )
                      }
                    />
                    <TableElement value={item?.customerDetails?.mobile} />
                    <TableElement
                      value={
                        <span>
                          {item?.customerDetails?.imei1} <br />
                          {item?.customerDetails?.imei2}
                        </span>
                      }
                    />
                    <TableElement
                      value={
                        <span>
                          {item?.user?.name} <br />
                          {item?.user?.mobile}
                        </span>
                      }
                    />

                    <TableElement value={item?.createdOn} />
                    <TableElement
                      value={
                        <Button onClick={() => handleEmiDetailsClick(item)}>
                          View
                        </Button>
                      }
                    />
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      {/* <Modal
        isOpen={isModalOpen}
        customer={selectedCustomer}
        onClose={() => setIsModalOpen(false)}
      /> */}
      <RegistrationDetails
        isOpen={isModalOpen}
        customer={selectedCustomer}
        onClose={() => setIsModalOpen(false)}
      />
    </>
  );
}

const RegistrationDetails = ({ isOpen, onClose, customer }) => {
  const dispatch = useDispatch();

  const [confirmActionDialogOpen, setConfirmActionDialogOpen] = useState(false);
  const [actionType, setActionType] = useState("");

  const handleAdminAction = async () => {
    if (!customer?.id || !actionType) return;
    try {
      dispatch(setLoadingPanel(true));
      const { data } = await adminActions(customer?.id, actionType);
      toast(data.message, { type: "success", autoClose: 1000 });
    } catch (err) {
      toast(
        `${err.response?.status} ${err?.response?.data?.message || "Error"}`,
        { type: "error", autoClose: 1000 }
      );
    } finally {
      dispatch(setLoadingPanel(false));
    }
  };

  return (
    <>
      <Dialog maxWidth="lg" fullWidth open={isOpen} onClose={onClose}>
        <DialogTitle>Registration Details</DialogTitle>

        <DialogContent dividers>
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-100">
              <tr className="divide-x divide-gray-200 ">
                <TableHeader heading="Name" />
                <TableHeader heading="Email" />
                <TableHeader heading="Mobile" />
                <TableHeader heading="Alternate Mobile" />
                <TableHeader heading="IMEI 1" />
                <TableHeader heading="IMEI 2" />
                <TableHeader heading="Serial Number" />
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              <tr className="divide-x divide-gray-200 hover:bg-slate-100">
                <TableElement value={customer?.customerDetails?.name} />
                <TableElement value={customer?.customerDetails?.email} />
                <TableElement value={customer?.customerDetails?.mobile} />
                <TableElement
                  value={customer?.customerDetails?.alternateMobile}
                />
                <TableElement value={customer?.customerDetails?.imei1} />
                <TableElement value={customer?.customerDetails?.imei2} />
                <TableElement value={customer?.customerDetails?.serialNumber} />
              </tr>
            </tbody>
          </table>
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-100">
              <tr className="divide-x divide-gray-200 ">
                <TableHeader heading="Version " />
                <TableHeader heading="Enterprise " />
                <TableHeader heading="Device ID" />
                <TableHeader heading="Brand" />
                <TableHeader heading="Model" />
                <TableHeader heading="Status" />
                <TableHeader heading="locked" />
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              <tr className="divide-x divide-gray-200 hover:bg-slate-100">
                <TableElement value={customer?.version || "ABSOLUTE"} />
                <TableElement value={customer?.enterprise || "-"} />
                <TableElement value={customer?.deviceData?.deviceId||"-"} />
                <TableElement value={customer?.deviceData?.brand} />
                <TableElement value={customer?.deviceData?.model} />
                <TableElement value={customer?.deviceData?.status} />
                <TableElement
                  value={
                    customer?.deviceData?.locked ? (
                      <p className="text-red-500">YES</p>
                    ) : (
                      <p className="text-green-500">NO</p>
                    )
                  }
                />
              </tr>
            </tbody>
          </table>
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-100">
              <tr className="divide-x divide-gray-200 ">
                <TableHeader heading="Aadhaar" />
                <TableHeader heading="PAN" />
                <TableHeader heading="Address" />
                <TableHeader heading="district" />
                <TableHeader heading="State" />
                <TableHeader heading="PIN" />
                <TableHeader heading="alternate Address" />
              </tr>
            </thead>

            <tbody className="divide-y divide-gray-200 bg-white">
              <tr className="divide-x divide-gray-200 hover:bg-slate-100">
                <TableElement value={customer?.kycDetails?.aadhaar} />
                <TableElement value={customer?.kycDetails?.pan} />
                <TableElement
                  value={
                    customer?.kycDetails?.address1
                      ? customer?.kycDetails?.address1 + ", "
                      : "" + customer?.kycDetails?.address2
                      ? customer?.kycDetails?.address2 + ", "
                      : "" + customer?.kycDetails?.landmark
                      ? customer?.kycDetails?.landmark + ", "
                      : ""
                  }
                />
                <TableElement value={customer?.kycDetails?.district} />
                <TableElement value={customer?.kycDetails?.state} />
                <TableElement value={customer?.kycDetails?.pinCode} />

                <TableElement value={customer?.kycDetails?.alternateAddress} />
              </tr>
            </tbody>
          </table>
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-100">
              <tr className="divide-x divide-gray-200 ">
                <TableHeader heading="Customer" />
                <TableHeader heading="Aadhaar Front" />
                <TableHeader heading="Aadhaar Back" />
                <TableHeader heading="Pan" />
                <TableHeader heading="Signature" />
                <TableHeader heading="Invoice" />
              </tr>
            </thead>

            <tbody className="divide-y divide-gray-200 bg-white">
              <tr className="divide-x divide-gray-200 hover:bg-slate-100">
                <TableElement
                  value={
                    customer?.kycDetails?.customerUrl ? (
                      <a
                        rel="noopener noreferrer"
                        href={customer?.kycDetails?.customerUrl}
                        target="_blank">
                        <img
                          src={customer?.kycDetails?.customerUrl}
                          alt="sign"
                          loading="lazy"
                          className="h-20 bg-white border-4"
                        />
                      </a>
                    ) : (
                      "-"
                    )
                  }
                />
                <TableElement
                  value={
                    customer?.kycDetails?.aadhaarUrl ? (
                      <a
                        rel="noopener noreferrer"
                        href={customer?.kycDetails?.aadhaarUrl}
                        target="_blank">
                        <img
                          src={customer?.kycDetails?.aadhaarUrl}
                          alt="sign"
                          loading="lazy"
                          className="h-20 bg-white border-4"
                        />
                      </a>
                    ) : (
                      "-"
                    )
                  }
                />
                <TableElement
                  value={
                    customer?.kycDetails?.aadhaarBackUrl ? (
                      <a
                        rel="noopener noreferrer"
                        href={customer?.kycDetails?.aadhaarBackUrl}
                        target="_blank">
                        <img
                          src={customer?.kycDetails?.aadhaarBackUrl}
                          alt="sign"
                          loading="lazy"
                          className="h-20 bg-white border-4"
                        />
                      </a>
                    ) : (
                      "-"
                    )
                  }
                />
                <TableElement
                  value={
                    customer?.kycDetails?.panUrl ? (
                      <a
                        rel="noopener noreferrer"
                        href={customer?.kycDetails?.panUrl}
                        target="_blank">
                        <img
                          src={customer?.kycDetails?.panUrl}
                          alt="sign"
                          loading="lazy"
                          className="h-20 bg-white border-4"
                        />
                      </a>
                    ) : (
                      "-"
                    )
                  }
                />
                <TableElement
                  value={
                    customer?.kycDetails?.signatureUrl ? (
                      <a
                        rel="noopener noreferrer"
                        href={customer?.kycDetails?.signatureUrl}
                        target="_blank">
                        <img
                          src={customer?.kycDetails?.signatureUrl}
                          alt="sign"
                          loading="lazy"
                          className="h-20 bg-white border-4 self-center"
                        />
                      </a>
                    ) : (
                      "-"
                    )
                  }
                />
                <TableElement
                  value={
                    customer?.kycDetails?.invoiceUrl ? (
                      <a
                        rel="noopener noreferrer"
                        href={customer?.kycDetails?.invoiceUrl}
                        target="_blank">
                        <img
                          src={customer?.kycDetails?.invoiceUrl}
                          alt="sign"
                          loading="lazy"
                          className="h-20 bg-white border-4"
                        />
                      </a>
                    ) : (
                      "-"
                    )
                  }
                />
              </tr>
            </tbody>
          </table>
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-100">
              <tr className="divide-x divide-gray-200 ">
                <TableHeader heading="EMI DATE" />
                <TableHeader heading="STATUS" />
                <TableHeader heading="AMOUNT" />
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {customer?.emiDetails?.emiDueDates?.map((item, i) => (
                <tr
                  key={i}
                  className="divide-x divide-gray-200 hover:bg-slate-100">
                  <TableElement value={formatDate(item?.emiDate)} />
                  <TableElement
                    value={
                      item?.status === "PAID" ? (
                        <p className="text-green-500">PAID</p>
                      ) : (
                        <p className="text-red-500">DUE</p>
                      )
                    }
                  />
                  <TableElement
                    value={"₹ " + customer?.emiDetails?.emiAmount}
                  />
                </tr>
              ))}
            </tbody>
          </table>

          {/* --- Action Buttons --- */}
        </DialogContent>
        <DialogActions>
          <div className="flex justify-evenly flex-wrap w-full">
            {/* , , , , , , , , REBOOT */}
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setActionType("LOCK");
                setConfirmActionDialogOpen(true);
              }}>
              LOCK
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setActionType("UNLOCK");
                setConfirmActionDialogOpen(true);
              }}>
              UNLOCK
            </Button>{" "}
            <Button
              variant="contained"
              color="error"
              onDoubleClick={() => {
                setActionType("UNINSTALL");
                setConfirmActionDialogOpen(true);
              }}>
              UNINSTALL
            </Button>{" "}
            <Button
              variant="contained"
              color="error"
              onDoubleClick={() => {
                setActionType("DEREGISTER_ZT");
                setConfirmActionDialogOpen(true);
              }}>
              DEREGISTER ZT
            </Button>{" "}
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setActionType("FETCH_LOCATION");
                setConfirmActionDialogOpen(true);
              }}>
              FETCH LOCATION
            </Button>{" "}
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setActionType("FETCH_SIM");
                setConfirmActionDialogOpen(true);
              }}>
              FETCH SIM
            </Button>
            <Button
              variant="contained"
              color="warning"
              onDoubleClick={() => {
                setActionType("REBOOT");
                setConfirmActionDialogOpen(true);
              }}>
              REBOOT
            </Button>
            <Button
              variant="contained"
              color="success"
              onClick={() => {
                setActionType("SYNC");
                setConfirmActionDialogOpen(true);
              }}>
              SYNC
            </Button>
          </div>
        </DialogActions>
      </Dialog>

      {/* --- Confirmation Dialog --- */}
      <Dialog
        open={confirmActionDialogOpen}
        onClose={() => setConfirmActionDialogOpen(false)}>
        <DialogTitle>Confirm Action</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to <b>{actionType}</b> this device?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setConfirmActionDialogOpen(false)}
            color="primary">
            Cancel
          </Button>
          <Button
            onClick={async () => {
              await handleAdminAction();
              setConfirmActionDialogOpen(false);
              onClose(); // Optional: close the main modal after action
            }}
            color="secondary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
